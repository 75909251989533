<template>
  <v-row
    no-gutters
    :style="{
      background: 'linear-gradient(0deg, rgba(74,165,235,1) 0%, rgba(46,149,228,1) 100%)',
      'padding-top': getMarginTopAndBottom * 0.9 + 'px',
      'padding-bottom': getMarginTopAndBottom * 0.6 + 'px',
      'margin-top': '-16px'
    }"
  >
    <v-col cols="0" sm="2" md="3"></v-col>
    <v-col cols="12" sm="8" md="6">
      <v-row no-gutters>
        <v-col cols="12" sm="6" style="padding-right:30px;padding-left:30px;">
          <div
            :style="{
              height: '100%',
              width: '100%',
              display: 'flex',
              'align-items': 'center'
            }"
          >
            <v-row no-gutters style="width:100%;">
              <v-col cols="12" :style="{ 'text-align': getTextAlign }">
                <span
                  :style="{
                    'font-size': getTitleFontSize1 + 'px',
                    'font-weight': 'bold',
                    color: '#FFF'
                  }"
                  >快速匯出商品</span
                >
              </v-col>
              <v-col cols="12">
                <span
                  :style="{
                    color: '#FFF',
                    'font-size': getContentFontSize + 'px'
                  }"
                  >商品資料建立完成後，你可以在網頁後台建立自己想要匯出的 Excel
                  表格格式，輕鬆地將批貨商品內容導入到您自有管理系統，或是上架到其他網路平台</span
                >
              </v-col>
              <v-col cols="12" style="margin-top:12px;">
                <span
                  :style="{
                    color: '#FFF',
                    'font-size': getContentFontSize + 'px'
                  }"
                  >※快速上架：蝦皮、Shopline、Cyberbiz、FBBuy+1</span
                >
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" sm="6" style="text-align:center;margin-top:20px;">
          <img src="@/assets/excel.png" alt="" width="60%;" style="max-width:300px;" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="0" sm="2" md="3"></v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    getTitleFontSize() {
      return parseInt(this.$store.state.screenWidth / 14) > 38
        ? 38
        : parseInt(this.$store.state.screenWidth / 14) < 18
        ? 18
        : parseInt(this.$store.state.screenWidth / 14);
    },
    getTitleFontSize1() {
      return parseInt(this.$store.state.screenWidth / 40) > 44
        ? 44
        : parseInt(this.$store.state.screenWidth / 40) < 22
        ? 22
        : parseInt(this.$store.state.screenWidth / 40);
    },
    getContentFontSize() {
      return this.$store.state.screenWidth / 80 < 14 ? 14 : this.$store.state.screenWidth / 80 > 18 ? 18 : this.$store.state.screenWidth / 80;
    },
    getMarginTopAndBottom() {
      let ans;
      if (this.$store.state.screenWidth < 600) {
        ans = this.$store.state.screenHeight * 0.1;
      } else if (this.$store.state.screenWidth >= 600 && this.$store.state.screenWidth < 960) {
        ans = this.$store.state.screenHeight * 0.11;
      } else if (this.$store.state.screenWidth >= 960 && this.$store.state.screenWidth < 1264) {
        ans = this.$store.state.screenHeight * 0.12;
      } else {
        ans = this.$store.state.screenHeight * 0.13;
      }
      return ans;
    },
    getTextAlign() {
      if (this.$store.state.screenWidth < 600) {
        return "center";
      } else {
        return "left";
      }
    }
  }
};
</script>
