<template>
  <v-row
    no-gutters
    :style="{
      'background-image': 'url(' + require('@/assets/854.jpg') + ')',
      'padding-top': getMarginTopAndBottom * 0.9 + 'px',
      'padding-bottom': getMarginTopAndBottom * 0.6 + 'px',
      'margin-top': '-10px'
    }"
  >
    <v-col cols="0" sm="2" md="3"></v-col>
    <v-col cols="12" sm="8" md="6">
      <v-row no-gutters>
        <v-col cols="7" style="text-align:center;">
          <img src="@/assets/for_all_business.png" alt="" width="80%;" ref="pic_1" />
        </v-col>
        <v-col cols="5" style="padding-right:20px;margin-left:-10px;">
          <div
            :style="{
              height: '100%',
              width: '100%',
              display: 'flex',
              'align-items': 'center'
            }"
          >
            <v-row no-gutters style="width:100%;">
              <v-col cols="12" id="ForAllBusinessTitle">
                <span
                  :style="{
                    'font-size': getTitleFontSize + 'px',
                    'font-weight': 'bold',
                    color: '#333'
                  }"
                  >銷售業通用</span
                >
              </v-col>
              <v-col cols="12" id="ForAllBusinessContent">
                <span
                  :style="{
                    color: '#333',
                    'font-size': getContentFontSize + 'px'
                  }"
                  >每次批貨，都可建立專屬的批貨專案，詳細的功能設定，連匯率換算也幫您搞定，適用大部份的零售批發業，代購、日用品、五金、零食、雜貨...等全行業皆可通用！</span
                >
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="0" sm="2" md="3"></v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    getTitleFontSize() {
      return this.$store.state.screenWidth / 30 < 26 ? 26 : this.$store.state.screenWidth / 30;
    },
    getContentFontSize() {
      return this.$store.state.screenWidth / 80 < 14 ? 14 : this.$store.state.screenWidth / 80;
    },
    getMarginTopAndBottom() {
      let ans;
      if (this.$store.state.screenWidth < 600) {
        ans = this.$store.state.screenHeight * 0.07;
      } else if (this.$store.state.screenWidth >= 600 && this.$store.state.screenWidth < 960) {
        ans = this.$store.state.screenHeight * 0.08;
      } else if (this.$store.state.screenWidth >= 960 && this.$store.state.screenWidth < 1264) {
        ans = this.$store.state.screenHeight * 0.09;
      } else {
        ans = this.$store.state.screenHeight * 0.1;
      }
      return ans;
    }
  }
};
</script>

<style scoped>
.ForAllBusinessContent {
  animation-duration: 1s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
</style>
