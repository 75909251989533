var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-carousel-item',{key:"carouselItem01",attrs:{"src":require("@/assets/banner1-2.jpg")}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{staticStyle:{"text-align":"center","margin-top":"-50px"},attrs:{"cols":"12","id":"bannerLogo"}},[_c('img',{staticStyle:{"shadow":"gray 0.1em 0.1em 0.2em","max-width":"160px"},attrs:{"src":_vm.getSrc,"alt":"","width":"20%;"}})]),_c('v-col',{staticStyle:{"text-align":"center","margin-top":"0px"},attrs:{"cols":"12","id":"bannerTitle"}},[_c('div',[_c('span',{style:({
              'font-size': _vm.getBannerTitleFontSize + 'px',
              color: _vm.getThemeColor,
              'font-weight': 'bold',
              'text-shadow': 'black 0.1em 0.1em 0.2em'
            })},[_vm._v(" 批貨上架超輕鬆 ")])])]),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1","sm":"2","md":"2","lg":"3"}}),_c('v-col',{attrs:{"cols":"10","sm":"8","md":"8","lg":"6"}},[_c('v-row',{style:({
                color: _vm.getThemeColor,
                'font-size': _vm.getBannerSubTitleFontSize + 'px',
                'text-shadow': 'black 0.1em 0.1em 0.2em'
              }),attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4","sm":"4","md":"2","id":"bannerSubTitle1"}},[_c('span',{style:({ margin: '0 auto' })},[_vm._v("拍照批貨")])]),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"2","id":"bannerSubTitle2"}},[_c('span',{style:({ margin: '0 auto' })},[_vm._v("同步編輯")])]),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"2","id":"bannerSubTitle3"}},[_c('span',{style:({ margin: '0 auto' })},[_vm._v("離線工作")])]),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"2","id":"bannerSubTitle4"}},[_c('span',{style:({ margin: '0 auto' })},[_vm._v("追加生成")])]),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"2","id":"bannerSubTitle5"}},[_c('span',{style:({ margin: '0 auto' })},[_vm._v("快速上架")])]),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"2","id":"bannerSubTitle6"}},[_c('span',{style:({ margin: '0 auto' })},[_vm._v("水印工具")])])],1)],1),_c('v-col',{attrs:{"cols":"1","sm":"2","md":"2","lg":"3"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }