var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{style:({
    background: 'linear-gradient(0deg, rgba(74,165,235,1) 0%, rgba(46,149,228,1) 100%)',
    padding: _vm.getMarginTopAndBottom * 0.6 + 'px 0'
  }),attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"0","sm":"2","md":"3"}}),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"text-align":"center","position":"relative"},attrs:{"cols":"12","sm":"12","md":"7"}},[_c('div',{staticStyle:{"z-index":"1","position":"relative"}},[_c('img',{ref:"pic_2",attrs:{"src":require("@/assets/www_02.png"),"alt":"","width":"65%;","id":"RecordByPhotoImg"}})]),_c('div',{staticStyle:{"z-index":"0","position":"absolute","top":"-1px","left":"-8px","width":"100%"}},[_c('img',{staticStyle:{"opacity":"0.4"},attrs:{"src":require("@/assets/feature_03_img_bg.png"),"alt":"","width":"88%"}})])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5"}},[_c('div',{style:({
            height: '100%',
            width: '100%',
            display: 'flex',
            'align-items': 'center',
            'margin-top': _vm.$store.state.screenWidth < 960 ? '16px' : '0px'
          })},[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{style:({ width: '100%', 'text-align': _vm.$store.state.screenWidth < 960 ? 'center' : 'left' }),attrs:{"cols":"12","id":"RecordByPhotoTitle"}},[_c('span',{style:({
                  'font-size': _vm.getTitleFontSize + 'px',
                  'font-weight': 'bold',
                  color: '#FFF'
                })},[_vm._v("拍照批貨")])]),_c('v-col',{style:({ width: '100%', padding: _vm.$store.state.screenWidth < 960 ? '0 60px' : '0 0' }),attrs:{"cols":"12","id":"RecordByPhotoContent"}},[_c('span',{style:({
                  color: '#FFF',
                  'font-size': _vm.getContentFontSize + 'px'
                })},[_vm._v("批貨採購新革命！用拍照的方式直接記錄商品，顏色、尺寸直接點選，價格也可依照您的設定自動生成建議價格，降低出錯率，批貨效率提升 500%")])])],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":"0","sm":"2","md":"3"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }