var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{style:({
    'background-image': 'url(' + require('@/assets/854.jpg') + ')',
    'padding-top': _vm.getMarginTopAndBottom * 0.9 + 'px',
    'padding-bottom': _vm.getMarginTopAndBottom * 0.6 + 'px',
    'margin-top': '-10px'
  }),attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"0","sm":"2","md":"3"}}),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"7"}},[_c('img',{ref:"pic_1",attrs:{"src":require("@/assets/for_all_business.png"),"alt":"","width":"80%;"}})]),_c('v-col',{staticStyle:{"padding-right":"20px","margin-left":"-10px"},attrs:{"cols":"5"}},[_c('div',{style:({
            height: '100%',
            width: '100%',
            display: 'flex',
            'align-items': 'center'
          })},[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","id":"ForAllBusinessTitle"}},[_c('span',{style:({
                  'font-size': _vm.getTitleFontSize + 'px',
                  'font-weight': 'bold',
                  color: '#333'
                })},[_vm._v("銷售業通用")])]),_c('v-col',{attrs:{"cols":"12","id":"ForAllBusinessContent"}},[_c('span',{style:({
                  color: '#333',
                  'font-size': _vm.getContentFontSize + 'px'
                })},[_vm._v("每次批貨，都可建立專屬的批貨專案，詳細的功能設定，連匯率換算也幫您搞定，適用大部份的零售批發業，代購、日用品、五金、零食、雜貨...等全行業皆可通用！")])])],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":"0","sm":"2","md":"3"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }