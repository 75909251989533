var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{style:({
    background: 'linear-gradient(0deg, rgba(74,165,235,1) 0%, rgba(46,149,228,1) 100%)',
    'padding-top': _vm.getMarginTopAndBottom * 0.9 + 'px',
    'padding-bottom': _vm.getMarginTopAndBottom * 0.6 + 'px',
    'margin-top': '-16px'
  }),attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"0","sm":"2","md":"3"}}),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"padding-right":"30px","padding-left":"30px"},attrs:{"cols":"12","sm":"6"}},[_c('div',{style:({
            height: '100%',
            width: '100%',
            display: 'flex',
            'align-items': 'center'
          })},[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{style:({ 'text-align': _vm.getTextAlign }),attrs:{"cols":"12"}},[_c('span',{style:({
                  'font-size': _vm.getTitleFontSize1 + 'px',
                  'font-weight': 'bold',
                  color: '#FFF'
                })},[_vm._v("快速匯出商品")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{style:({
                  color: '#FFF',
                  'font-size': _vm.getContentFontSize + 'px'
                })},[_vm._v("商品資料建立完成後，你可以在網頁後台建立自己想要匯出的 Excel 表格格式，輕鬆地將批貨商品內容導入到您自有管理系統，或是上架到其他網路平台")])]),_c('v-col',{staticStyle:{"margin-top":"12px"},attrs:{"cols":"12"}},[_c('span',{style:({
                  color: '#FFF',
                  'font-size': _vm.getContentFontSize + 'px'
                })},[_vm._v("※快速上架：蝦皮、Shopline、Cyberbiz、FBBuy+1")])])],1)],1)]),_c('v-col',{staticStyle:{"text-align":"center","margin-top":"20px"},attrs:{"cols":"12","sm":"6"}},[_c('img',{staticStyle:{"max-width":"300px"},attrs:{"src":require("@/assets/excel.png"),"alt":"","width":"60%;"}})])],1)],1),_c('v-col',{attrs:{"cols":"0","sm":"2","md":"3"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }