<template>
  <v-row no-gutters style="background:rgba(238,238,243,0.6);" id="DownloadContainer">
    <v-carousel :height="getBannerHeight + 'px'" hide-delimiter-background :show-arrows="false" :hide-delimiters="true">
      <v-carousel-item>
        <v-row no-gutters class="fill-height" align="center" justify="center" style="width:100%;padding:0 30px;">
          <v-col cols="0" sm="1" md="2" lg="3"></v-col>
          <v-col cols="0" sm="10" md="8" lg="6">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="5" style="text-align:center;">
                <img src="@/assets/android_logo.png" alt="" :width="getAndroidLogoWidth + 'px'" style="max-width:240px;" />
              </v-col>
              <v-col cols="12" sm="12" md="7">
                <div :style="{ height: '100%', width: '100%', display: 'flex', 'align-items': 'center' }">
                  <v-row no-gutters>
                    <v-col cols="12" :style="{ 'text-align': getTextAlign, 'margin-top': getTitleMarginTop + 'px' }">
                      <div>
                        <span
                          :style="{
                            'font-weight': 'bold',
                            'font-size': getTitleFontSize + 'px',
                            color: '#444'
                          }"
                          >下載 EppBuyer</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" :style="{ 'text-align': getTextAlign }">
                      <div>
                        <span :style="{ 'font-size': getContentFontSize + 'px', color: '#666' }">新用戶享 2 個月無廣告試用期，試用滿意再購買</span>
                      </div>
                    </v-col>
                    <v-col cols="12" :style="{ 'text-align': getTextAlign }">
                      <small style="color:#999"
                        >版本：v{{ apkVersion }} <span style="margin-left:10px;">(Android Only - 目前僅提供 android 版本下載)</span></small
                      >
                    </v-col>
                    <v-col cols="12" :style="{ 'text-align': getTextAlign, 'margin-top': '20px' }">
                      <!-- <v-btn x-large color="#A2BD51" depressed width="200px" :href="'/EppBuyer v' + apkVersion + '.apk'">立即下載</v-btn> -->
                      <img src="@/assets/google_play.png" @click="toGooglePlay" style="cursor:pointer;max-width:200px;" />
                      <!-- <v-btn
                        x-large
                        color="#A2BD51"
                        depressed
                        width="200px"
                        :href="'https://play.google.com/store/apps/details?id=com.bruce.goodbuyer_v11'"
                        >立即下載</v-btn
                      > -->
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="0" sm="1" md="2" lg="3"></v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      apkVersion: this.getVersion()
    };
  },
  methods: {
    async getVersion() {
      let ans = false;
      await this.$axios
        .get("/api/apis/getapkversion.php")
        .then(
          function(res) {
            this.apkVersion = res.data;
            ans = true;
          }.bind(this)
        )
        .catch(
          function() {
            this.apkVersion = "1.0.000";
          }.bind(this)
        );
      return ans;
    },
    toGooglePlay() {
      setTimeout(() => {
        window.open("https://play.google.com/store/apps/details?id=com.bruce.goodbuyer_v11", "_blank");
      }, 100);
    }
  },
  computed: {
    getBannerHeight() {
      let ans;
      if (this.$store.state.screenWidth < 600) {
        ans = this.$store.state.screenHeight * 0.65;
      } else if (this.$store.state.screenWidth >= 600 && this.$store.state.screenWidth < 960) {
        ans = this.$store.state.screenHeight * 0.5;
      } else if (this.$store.state.screenWidth >= 960 && this.$store.state.screenWidth < 1264) {
        ans = this.$store.state.screenHeight * 0.5;
      } else {
        ans = this.$store.state.screenHeight * 0.5;
      }
      return ans;
    },
    getAndroidLogoWidth() {
      let ans;
      if (this.$store.state.screenWidth < 960) {
        ans = this.$store.state.screenWidth * 0.3;
      } else if (this.$store.state.screenWidth >= 960 && this.$store.state.screenWidth < 1264) {
        ans = this.$store.state.screenWidth * 0.2;
      } else {
        ans = this.$store.state.screenWidth * 0.15;
      }
      return ans;
    },
    getTitleFontSize() {
      return this.$store.state.screenWidth / 30 < 36 ? 36 : this.$store.state.screenWidth / 30;
    },
    getContentFontSize() {
      return this.$store.state.screenWidth / 80 < 14 ? 14 : this.$store.state.screenWidth / 80;
    },
    getTextAlign() {
      return this.$store.state.screenWidth < 960 ? "center" : "left";
    },
    getTitleMarginTop() {
      return this.$store.state.screenWidth < 960 ? 20 : 0;
    }

    // async downloadAppFile(){

    // }
  }
};
</script>
