<template>
  <v-row no-gutters style="width:100%;background:linear-gradient(0deg, rgba(90,173,237,1) 0%, rgba(74,165,235,1) 100%);padding-bottom:70px;">
    <v-col cols="0" sm="2"></v-col>
    <v-col cols="12" sm="8">
      <v-row no-gutters style="width:100%;">
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-row
            no-gutters
            :style="{
              'margin-left': '8px',
              'margin-right': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-top': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-bottom': $store.state.screenWidth < 600 ? '4px' : '8px',
              'border-radius': '20px',
              padding: '30px 20px',
              background: 'white',
              'box-shadow': '#999 1px 1px 5px'
            }"
            align="center"
          >
            <v-col cols="12" style="text-align:center;">
              <img src="@/assets/www_14.png" alt="" style="max-width:100px;" width="50%" />
              <div :style="{ 'font-size': getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' }">拍照批貨</div>
              <div :style="{ 'font-size': getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' }">
                批貨時直接拍照建檔，商品內容簡單點選，快速建立完整商品資訊，批貨記錄變得超輕鬆
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-row
            no-gutters
            :style="{
              'margin-left': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-right': '8px',
              'margin-top': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-bottom': $store.state.screenWidth < 600 ? '4px' : '8px',
              'border-radius': '20px',
              padding: '30px 20px',
              background: 'white',
              'box-shadow': '#999 1px 1px 5px'
            }"
            align="center"
          >
            <v-col cols="12" style="text-align:center;">
              <img src="@/assets/www_15.png" alt="" style="max-width:100px;" width="50%" />
              <div :style="{ 'font-size': getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' }">同步編輯</div>
              <div :style="{ 'font-size': getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' }">
                建檔後可直接上傳，其他夥伴可即時讀取及協助編輯商品資訊，作業效率瞬間提升 500%
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-row
            no-gutters
            :style="{
              'margin-left': '8px',
              'margin-right': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-top': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-bottom': $store.state.screenWidth < 600 ? '4px' : '8px',
              'border-radius': '20px',
              padding: '30px 20px',
              background: 'white',
              'box-shadow': '#999 1px 1px 5px'
            }"
            align="center"
          >
            <v-col cols="12" style="text-align:center;">
              <img src="@/assets/www_16.png" alt="" style="max-width:100px;" width="50%" />
              <div :style="{ 'font-size': getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' }">離線工作</div>
              <div :style="{ 'font-size': getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' }">
                批貨時沒有網路連接? EPPBUYER 離線時一樣可以正常工作，隨時隨地都可保持戰鬥力
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-row
            no-gutters
            :style="{
              'margin-left': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-right': '8px',
              'margin-top': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-bottom': $store.state.screenWidth < 600 ? '4px' : '8px',
              'border-radius': '20px',
              padding: '30px 20px',
              background: 'white',
              'box-shadow': '#999 1px 1px 5px'
            }"
            align="center"
          >
            <v-col cols="12" style="text-align:center;">
              <img src="@/assets/www_17.png" alt="" style="max-width:100px;" width="50%" />
              <div :style="{ 'font-size': getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' }">追加生成</div>
              <div :style="{ 'font-size': getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' }">
                網頁後台提供方便的追加單建立功能，輕鬆建立轉出追加 Excel 表格，精準追加不出錯
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-row
            no-gutters
            :style="{
              'margin-left': '8px',
              'margin-right': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-top': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-bottom': $store.state.screenWidth < 600 ? '4px' : '8px',
              'border-radius': '20px',
              padding: '30px 20px',
              background: 'white',
              'box-shadow': '#999 1px 1px 5px'
            }"
            align="center"
          >
            <v-col cols="12" style="text-align:center;">
              <img src="@/assets/www_18.png" alt="" style="max-width:100px;" width="50%" />
              <div :style="{ 'font-size': getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' }">快速上架</div>
              <div :style="{ 'font-size': getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' }">
                網頁後台設定您需要的格式，直接輸出上架賣場用的商品文字資訊，不需再手動一遍遍輸入
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-row
            no-gutters
            :style="{
              'margin-left': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-right': '8px',
              'margin-top': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-bottom': $store.state.screenWidth < 600 ? '4px' : '8px',
              'border-radius': '20px',
              padding: '30px 20px',
              background: 'white',
              'box-shadow': '#999 1px 1px 5px'
            }"
            align="center"
          >
            <v-col cols="12" style="text-align:center;">
              <img src="@/assets/www_19.png" alt="" style="max-width:100px;" width="50%" />
              <div :style="{ 'font-size': getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' }">水印工具</div>
              <div :style="{ 'font-size': getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' }">
                EPPBUYER 提供簡單浮水印工具，拍攝的商品圖片直接幫您壓上浮水印，品牌形象瞬間提升
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-row
            no-gutters
            :style="{
              'margin-left': '8px',
              'margin-right': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-top': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-bottom': $store.state.screenWidth < 600 ? '4px' : '8px',
              'border-radius': '20px',
              padding: '30px 20px',
              background: 'white',
              'box-shadow': '#999 1px 1px 5px'
            }"
            align="center"
          >
            <v-col cols="12" style="text-align:center;">
              <img src="@/assets/www_20.png" alt="" style="max-width:100px;" width="50%" />
              <div :style="{ 'font-size': getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' }">匯率換算</div>
              <div :style="{ 'font-size': getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' }">
                EppBuyer 提供匯率換算功能，批貨專案中直接設定貨幣及匯率，輕鬆解決匯率換算的麻煩
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-row
            no-gutters
            :style="{
              'margin-left': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-right': '8px',
              'margin-top': $store.state.screenWidth < 600 ? '4px' : '8px',
              'margin-bottom': $store.state.screenWidth < 600 ? '4px' : '8px',
              'border-radius': '20px',
              padding: '30px 20px',
              background: 'white',
              'box-shadow': '#999 1px 1px 5px'
            }"
            align="center"
          >
            <v-col cols="12" style="text-align:center;">
              <img src="@/assets/www_21.png" alt="" style="max-width:100px;" width="50%" />
              <div :style="{ 'font-size': getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' }">價格公式</div>
              <div :style="{ 'font-size': getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' }">
                每次訂定價格都很頭痛？ EppBuyer 提供三種換算公式，輸入成本就搞定所有的販售價格
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="0" sm="2"></v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    getTitleFontSize() {
      return this.$store.state.screenWidth / 90 < 17 ? 17 : this.$store.state.screenWidth / 90;
    },
    getContentFontSize() {
      return this.$store.state.screenWidth / 120 < 13 ? 13 : this.$store.state.screenWidth / 120;
    }
  }
};
</script>
