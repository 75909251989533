<template>
  <v-carousel-item key="carouselItem01" src="@/assets/banner1-2.jpg">
    <v-row no-gutters class="fill-height" align="center" justify="center">
      <v-row no-gutters align="center" justify="center" style="width:100%">
        <!-- <v-col cols="12" style="text-align:center;margin-top:-90px;" id="bannerLogo">
          <img :src="getSrc" alt="" width="70px;" style="shadow:gray 0.1em 0.1em 0.2em" />
        </v-col> -->
        <v-col cols="12" style="text-align:center;margin-top:-50px;" id="bannerLogo">
          <img :src="getSrc" alt="" width="20%;" style="shadow:gray 0.1em 0.1em 0.2em;max-width:160px;" />
        </v-col>
        <v-col cols="12" style="text-align:center;margin-top:0px;" id="bannerTitle">
          <div>
            <span
              :style="{
                'font-size': getBannerTitleFontSize + 'px',
                color: getThemeColor,
                'font-weight': 'bold',
                'text-shadow': 'black 0.1em 0.1em 0.2em'
              }"
            >
              批貨上架超輕鬆
            </span>
          </div>
        </v-col>
        <v-col cols="12" style="text-align:center;">
          <v-row no-gutters>
            <v-col cols="1" sm="2" md="2" lg="3"></v-col>
            <v-col cols="10" sm="8" md="8" lg="6">
              <v-row
                no-gutters
                :style="{
                  color: getThemeColor,
                  'font-size': getBannerSubTitleFontSize + 'px',
                  'text-shadow': 'black 0.1em 0.1em 0.2em'
                }"
              >
                <v-col cols="4" sm="4" md="2" id="bannerSubTitle1">
                  <span :style="{ margin: '0 auto' }">拍照批貨</span>
                </v-col>
                <v-col cols="4" sm="4" md="2" id="bannerSubTitle2">
                  <span :style="{ margin: '0 auto' }">同步編輯</span>
                </v-col>
                <v-col cols="4" sm="4" md="2" id="bannerSubTitle3">
                  <span :style="{ margin: '0 auto' }">離線工作</span>
                </v-col>
                <v-col cols="4" sm="4" md="2" id="bannerSubTitle4">
                  <span :style="{ margin: '0 auto' }">追加生成</span>
                </v-col>
                <v-col cols="4" sm="4" md="2" id="bannerSubTitle5">
                  <span :style="{ margin: '0 auto' }">快速上架</span>
                </v-col>
                <v-col cols="4" sm="4" md="2" id="bannerSubTitle6">
                  <span :style="{ margin: '0 auto' }">水印工具</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1" sm="2" md="2" lg="3"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
  </v-carousel-item>
</template>

<script>
export default {
  props: {
    themeColor: { type: Number }
  },
  computed: {
    getBannerTitleFontSize() {
      return parseInt(this.$store.state.screenWidth / 12) > 100
        ? 100
        : parseInt(this.$store.state.screenWidth / 12) < 36
        ? 36
        : parseInt(this.$store.state.screenWidth / 12);
    },
    getBannerSubTitleFontSize() {
      return parseInt(this.$store.state.screenWidth / 50) >= 14
        ? parseInt(this.$store.state.screenWidth / 50) <= 22
          ? parseInt(this.$store.state.screenWidth / 50)
          : 22
        : 14;
    },
    getThemeColor() {
      return this.themeColor == 0 ? "#222" : "white";
    },
    getSrc() {
      return this.themeColor == 0 ? require("@/assets/logo.png") : require("@/assets/www_11.png");
    }
  }
};
</script>

<style scoped>
.bannerSubTitle1 {
  /* //重新设置动画时间 */
  animation-duration: 1s;
  /* //延迟执行动画 */
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.bannerSubTitle2 {
  /* //重新设置动画时间 */
  animation-duration: 1s;
  /* //延迟执行动画 */
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.bannerSubTitle3 {
  /* //重新设置动画时间 */
  animation-duration: 1s;
  /* //延迟执行动画 */
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.bannerSubTitle4 {
  /* //重新设置动画时间 */
  animation-duration: 1s;
  /* //延迟执行动画 */
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.bannerSubTitle5 {
  /* //重新设置动画时间 */
  animation-duration: 1s;
  /* //延迟执行动画 */
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.bannerSubTitle6 {
  /* //重新设置动画时间 */
  animation-duration: 1s;
  /* //延迟执行动画 */
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
</style>
