<template>
  <v-row no-gutters style="background:rgba(233,233,233,0.6);padding-top:60px;padding-bottom:40px;">
    <v-col cols="1" sm="2"></v-col>
    <v-col cols="10" sm="8">
      <v-row no-gutters style="width:100%;">
        <v-col cols="12" sm="12" md="4" style="padding-bottom:20px;text-align:center">
          <img src="@/assets/logo.png" alt="eppbuyer" width="100px" />
          <div>EppBuyer</div>
        </v-col>
        <v-col cols="12" sm="12" md="4" style="padding-bottom:20px;">
          <v-row no-gutters style="margin-bottom:12px;" align="center" justify="center">
            <span style="font-size:18px;"> <strong style="margin-right:10px;">About</strong> <strong>關於</strong> </span>
          </v-row>
          <v-row no-gutters style="margin-bottom:6px;" align="center" justify="center">
            <span style="font-size:14px;padding:0 60px;">
              EppBuyer 是市面上唯一的自動化採購批貨
              APP，搭配網頁後台管理功能，可解決您在採購進貨流程中大部份的麻煩及重複性工作，節省您寶貴的時間，讓您專注於商品行銷
            </span>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4" style="padding-bottom:20px;">
          <v-row no-gutters style="margin-bottom:12px;" align="center" justify="center">
            <span style="font-size:18px;"> <strong style="margin-right:10px;">Contact Us</strong> <strong>聯繫我們</strong> </span>
          </v-row>
          <v-row no-gutters style="100%" align="center" justify="center">
            <div style="width:auto;display:inline-block !important;display:inline;">
              <v-row no-gutters style="margin-bottom:6px;">
                <span style="font-size:15px;"
                  ><v-icon @click="toFacebook" style="color:#444;margin-right:4px;">mdi-facebook</v-icon>
                  <a style="color:#222" @click="toFacebook">Facebook</a>
                </span>
              </v-row>
              <v-row no-gutters style="margin-bottom:6px;">
                <span style="font-size:15px;"
                  ><v-icon @click="toWechat" style="color:#444;margin-right:4px;">mdi-wechat</v-icon>
                  <a style="color:#222" @click="toWechat">WeChat</a>
                </span>
              </v-row>
              <v-row no-gutters style="margin-bottom:6px;">
                <span style="font-size:15px;"
                  ><v-icon style="color:#444;margin-right:4px;">mdi-email</v-icon> <a style="color:#444">service@eppbuyer.com</a>
                </span>
              </v-row>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1" sm="2"></v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    toFacebook() {
      setTimeout(() => {
        window.open("https://www.facebook.com/eppbuyer", "_blank");
      }, 100);
      this.leaveFocus();
    },
    toWechat() {
      this.$emit("showWeChatDialog");
      this.leaveFocus();
    },
    leaveFocus() {
      if (document.activeElement != document.body) {
        document.activeElement.blur();
      }
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>
