<template>
  <v-row no-gutters :style="{ 'padding-top': getMarginTopAndBottom + 'px' }">
    <v-col cols="12" style="text-align:center;" id="PowerfulAdminTitle">
      <span :style="{ 'font-size': getTitleFontSize + 'px', 'font-weight': 'bold', color: '#333' }">後台管理</span>
    </v-col>
    <v-col cols="12" style="padding:0 30px;" id="PowerfulAdminContent">
      <div style="max-width:300px;margin:0 auto;">
        <span :style="{ color: '#333', 'font-size': getContentFontSize + 'px' }"
          >我們知道只有批貨記錄功能是不夠的，EppBuyer
          有強大的網頁後台管理功能，讓您可以多人同步編輯，快速上架、建立追加單...等等的超貼心設計，強大完整的功能等您來體驗！</span
        >
      </div>
    </v-col>
    <v-col cols="12" style="text-align:center;margin-top:30px;">
      <img src="@/assets/notebook.png" alt="" width="70%;" ref="pic_1" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    getTitleFontSize() {
      return this.$store.state.screenWidth / 30 < 24 ? 24 : this.$store.state.screenWidth / 30 > 40 ? 40 : this.$store.state.screenWidth / 30;
    },
    getContentFontSize() {
      return this.$store.state.screenWidth / 80 < 14 ? 14 : this.$store.state.screenWidth / 80 > 20 ? 20 : this.$store.state.screenWidth / 80;
    },
    getMarginTopAndBottom() {
      let ans;
      if (this.$store.state.screenWidth < 600) {
        ans = this.$store.state.screenHeight * 0.07;
      } else if (this.$store.state.screenWidth >= 600 && this.$store.state.screenWidth < 960) {
        ans = this.$store.state.screenHeight * 0.08;
      } else if (this.$store.state.screenWidth >= 960 && this.$store.state.screenWidth < 1264) {
        ans = this.$store.state.screenHeight * 0.09;
      } else {
        ans = this.$store.state.screenHeight * 0.1;
      }
      return ans;
    }
  }
};
</script>

<style scoped>
.PowerfulAdminContent {
  animation-duration: 1s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
</style>
