<template>
  <v-row no-gutters>
    <v-carousel
      :height="getBannerHeight + 'px'"
      hide-delimiter-background
      :show-arrows="bannerPicMoreThanOne"
      :hide-delimiters="!bannerPicMoreThanOne"
    >
      <BannerItem1 :themeColor="themeColor"></BannerItem1>
    </v-carousel>
    <WhyChooseUs></WhyChooseUs>
    <RecordByPhoto></RecordByPhoto>
    <AllFeatures></AllFeatures>
    <PowerfulAdmin></PowerfulAdmin>
    <ForAllBusiness></ForAllBusiness>
    <ExportExcel></ExportExcel>
    <TimeIsMoney></TimeIsMoney>
    <DownloadApp></DownloadApp>

    <PriceMenu v-if="$store.state.screenWidth >= 943" @showBuyDialog="showBuyDialog"></PriceMenu>
    <PriceMenuMobile v-if="$store.state.screenWidth < 943" @showBuyDialog="showBuyDialog"></PriceMenuMobile>
    <ContactUs @showWeChatDialog="showWeChatDialog"></ContactUs>
    <Footer></Footer>
    <DialogBuy :show="$store.state.showBuyDialog" :itemObj="showDialogItemObj" @closeDialog="closeBuyDialog"></DialogBuy>
    <DialogWeChat :show="showWeChat" @closeWeChatDialog="closeWeChatDialog"></DialogWeChat>
  </v-row>
</template>

<script>
import BannerItem1 from "@/components/Home_BannerItem1.vue"; //banner第一页
// import CarouselItem02 from "@/components/CarouselItem02.vue"; //banner第二页
import WhyChooseUs from "@/components/Home_WhyChooseUs.vue";
import RecordByPhoto from "@/components/Home_RecordByPhoto.vue";
import PowerfulAdmin from "@/components/Home_PowerfulAdmin.vue";
import ForAllBusiness from "@/components/Home_ForAllBusiness.vue";
import DownloadApp from "@/components/Home_DownloadApp.vue";
import AllFeatures from "@/components/Home_AllFeatures.vue";
import PriceMenu from "@/components/Home_PriceMenu.vue";
import PriceMenuMobile from "@/components/Home_PriceMenuMobile.vue";
import TimeIsMoney from "@/components/Home_TimeIsMoney.vue";
import ContactUs from "@/components/Home_ContactUs.vue";
import Footer from "@/components/Home_Footer.vue";
import DialogBuy from "@/components/Home_DialogBuy.vue";
import DialogWeChat from "@/components/Home_DialogWeChat.vue";
import ExportExcel from "@/components/Home_ExportExcel.vue";

export default {
  props: {
    themeColor: { type: Number }
  },
  data() {
    return {
      bannerItems: 1, //banner页数，大於1的话会显示左右按钮及下方区 delimiters
      animateItemList: [
        //動畫元素id清單
        "bannerLogo",
        "bannerTitle",
        "bannerSubTitle1",
        "bannerSubTitle2",
        "bannerSubTitle3",
        "bannerSubTitle4",
        "bannerSubTitle5",
        "bannerSubTitle6",
        "RecordByPhotoImg",
        "RecordByPhotoTitle",
        "RecordByPhotoContent",
        "ForAllBusinessTitle",
        "ForAllBusinessContent",
        "PowerfulAdminTitle",
        "PowerfulAdminContent"
      ],
      showDialog: false,
      showWeChat: false,
      showDialogItemObj: {}
    };
  },
  components: {
    BannerItem1,
    // CarouselItem02
    WhyChooseUs,
    RecordByPhoto,
    PowerfulAdmin,
    ForAllBusiness,
    DownloadApp,
    AllFeatures,
    PriceMenu,
    PriceMenuMobile,
    TimeIsMoney,
    ContactUs,
    Footer,
    DialogBuy,
    DialogWeChat,
    ExportExcel
  },
  beforeMount() {
    this.$nextTick(function() {
      //监听滚动事件
      this.handleScroll();
    });
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("scroll", this.handleScroll);
    });
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    //依寬度動態改變banner高度
    getBannerHeight() {
      let ans;
      if (this.$store.state.screenWidth < 600) {
        ans = this.$store.state.screenHeight * 0.7;
      } else if (this.$store.state.screenWidth >= 600 && this.$store.state.screenWidth < 960) {
        ans = this.$store.state.screenHeight * 0.75;
      } else if (this.$store.state.screenWidth >= 960 && this.$store.state.screenWidth < 1264) {
        ans = this.$store.state.screenHeight * 0.85;
      } else {
        ans = this.$store.state.screenHeight * 0.85;
      }
      return ans;
    },
    //banner的項目個數，大於1的話回傳 true
    bannerPicMoreThanOne() {
      return this.bannerItems > 1 ? true : false;
    },
    getThemeColor() {
      return this.themeColor == 0 ? "black" : "white";
    }
  },
  methods: {
    showBuyDialog(item) {
      this.showDialogItemObj = item;
      this.$store.commit("setShowBuyDialog", true);
    },

    closeBuyDialog() {
      this.showDialogItemObj = {};
      this.$store.commit("setShowBuyDialog", false);
    },
    showWeChatDialog() {
      this.showWeChat = true;
    },
    closeWeChatDialog() {
      this.showWeChat = false;
    },
    //主頁面滾動加載動畫功能
    handleScroll() {
      var domHeight = document.documentElement.clientHeight; // 获取视窗高度
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // dom滚动位置
      var item;
      var itemHeight;
      var offsetTop;
      var bottom;

      this.animateItemList.map(i => {
        item = document.getElementById(i); //被監聽的元素
        if (item != undefined) {
          itemHeight = item.scrollHeight; //被監聽的元素高度
          offsetTop = item.offsetTop; //被監聽的元素上緣離頂部距離
          bottom = itemHeight + offsetTop; //被監聽元素距離底部的高度 + 元素本身高度
          if (domHeight + scrollTop > offsetTop && scrollTop < bottom - 80) {
            switch (i) {
              case "bannerLogo":
                item.className = "col col-12 animated zoomIn";
                break;
              case "bannerTitle":
                item.className = "animated zoomIn";
                break;
              case "bannerSubTitle1":
              case "bannerSubTitle2":
              case "bannerSubTitle3":
              case "bannerSubTitle4":
              case "bannerSubTitle5":
              case "bannerSubTitle6":
                item.className = "col-sm-4 col-md-2 col-4 animated zoomIn " + i;
                break;
              case "ForAllBusinessTitle":
                item.className = "animated fadeIn";
                break;
              case "PowerfulAdminTitle":
                item.className = "col col-12 animated fadeIn";
                break;
              case "ForAllBusinessContent":
                item.className = "animated fadeIn " + i;
                break;
              case "PowerfulAdminContent":
                item.className = "col col-12 animated fadeIn " + i;
                break;
              case "RecordByPhotoImg":
              case "RecordByPhotoTitle":
              case "RecordByPhotoContent":
                item.className = "animated bounceIn " + i;
                break;
            }
          } else {
            //滑動到元件時重複動畫
            // switch (i) {
            //   case "bannerLogo":
            //     item.className = "col col-12";
            //     break;
            //   case "bannerTitle":
            //     item.className = "";
            //     break;
            //   case "bannerSubTitle1":
            //   case "bannerSubTitle2":
            //   case "bannerSubTitle3":
            //   case "bannerSubTitle4":
            //   case "bannerSubTitle5":
            //   case "bannerSubTitle6":
            //     item.className = "col-sm-4 col-md-2 col-4";
            //     break;
            //   case "RecordByPhotoTitle":
            //   case "ForAllBusinessTitle":
            //   case "PowerfulAdminTitle":
            //   case "RecordByPhotoContent":
            //   case "ForAllBusinessContent":
            //   case "PowerfulAdminContent":
            //   case "RecordByPhotoImg":
            //     item.className = "";
            //     break;
            // }
          }
        }
      });
    }
  }
};
</script>
