<template>
  <v-row no-gutters justify="center" align="center">
    <v-card elevation="2" light style="cursor:pointer;border-radius:20px;max-width:320px;padding:20px;margin:16px;" @click="showDialog">
      <v-row no-gutters style="width:100%;margin-top:8px;">
        <v-col cols="8">
          <div style="width:100%;">
            <div>
              <span
                :style="{
                  color: '#444',
                  'font-weight': 'bold',
                  'font-size': '24px'
                }"
                >{{ itemObj.memberName }}</span
              >
            </div>
          </div>
          <div style="width:100%;color:#bbb;margin-top:4px;">
            <span style="font-size:12px;">推廣價格至 2021/12/31</span>
          </div>
        </v-col>
        <v-col cols="4" style="text-align:right;margin-top:6px;">
          <img :src="require('@/assets/' + itemObj.imgPath)" alt="" width="60px;" height="60px;" style="margin-top:-6px;" />
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-text style="padding:0px;">
        <div style="width:100%;text-align:left;margin-top:20px;">
          <span style="font-size:38px;color:#1976d2;">{{ itemObj.price }}</span>
          <span style="margin-left:8px;color:rgba(0, 0, 0, 0.87)">/ {{ itemObj.monthOrYear }} (NTD)</span>
        </div>
        <div style="width:100%;text-align:left;text-decoration:line-through; color:#FF0000;margin-bottom:6px;">
          <span style="color:#666;font-size:12px !important;">原價：{{ itemObj.oldPrice }} / {{ itemObj.monthOrYear }}</span>
        </div>
        <v-divider></v-divider>

        <div style="width:100%;text-align:center;margin-bottom:8px;margin-top:18px;margin-left:4px;" v-if="itemObj.plugin.length > 0">
          <v-row no-gutters align="center" justify="center" style="margin-top:4px;" v-for="(f, index) in itemObj.plugin" :key="f">
            <div style="width:260px;">
              <v-row no-gutters>
                <v-col cols="12" style="text-align:left;">
                  <span
                    style="background:#1976d2;color:white;border-radius:4px;margin-right:4px;padding-left:6px;padding-right:4px;font-size:12px;padding-top:2px;padding-bottom:2px;"
                    v-if="itemObj.memberLevel > 3 && index != 0"
                  >
                    年繳{{ getMore(itemObj.memberLevel, index) }}
                  </span>
                  <span style="color:#666;font-weight:bold;"
                    >{{ f }}
                    {{
                      f.indexOf("每月批貨專案") != -1
                        ? itemObj.projectQty
                        : f.indexOf("每月批貨商品") != -1
                        ? itemObj.productQty
                        : f.indexOf("匯出範本") != -1
                        ? itemObj.exportTemplateLimit
                        : ""
                    }}</span
                  >
                </v-col>
              </v-row>
            </div>
          </v-row>
        </div>

        <v-row no-gutters style="margin-top:2px;margin-left:7px;" v-for="item in features" :key="item">
          <div style="width:180px;">
            <v-row no-gutters>
              <v-col cols="12">
                <v-icon style="color:#26b116;">mdi-check</v-icon>
                <span> {{ item }}</span>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
export default {
  props: {
    itemObj: { type: Object, required: true },
    hover: { type: Boolean },
    features: {
      type: Array,
      default: function() {
        return ["拍照批貨建檔", "網頁後台同步編輯", "離線工作", "追加單快速建立", "上架文字設定", "簡單浮水印功能", "自定價格公式", "匯率換算功能"];
      }
    }
  },
  computed: {
    getMore() {
      return function(level, index) {
        switch (level) {
          case 4:
            if (index == 1) {
              return "+20";
            } else if (index == 2) {
              return "限定";
            } else if (index == 3) {
              return "限定";
            } else {
              return "";
            }
          case 5:
            if (index == 1) {
              return "+50";
            } else if (index == 2) {
              return "限定";
            } else if (index == 3) {
              return "限定";
            } else {
              return "";
            }
          case 6:
            if (index == 1) {
              return "+100";
            } else if (index == 2) {
              return "限定";
            } else if (index == 3) {
              return "限定";
            } else {
              return "";
            }
          default:
            return 0;
        }
      };
    },
    getMoreTemplateQty() {
      return 9;
    }
  },
  methods: {
    showDialog() {
      this.$emit("showDialog", this.itemObj);
    }
  }
};
</script>
