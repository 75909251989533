<template>
  <v-row
    no-gutters
    :style="{
      background: 'linear-gradient(0deg, rgba(74,165,235,1) 0%, rgba(46,149,228,1) 100%)',
      padding: getMarginTopAndBottom * 0.6 + 'px 0'
    }"
  >
    <v-col cols="0" sm="2" md="3"></v-col>
    <v-col cols="12" sm="8" md="6">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="7" style="text-align:center;position:relative;">
          <div style="z-index:1;position:relative;">
            <img src="@/assets/www_02.png" alt="" width="65%;" ref="pic_2" id="RecordByPhotoImg" />
          </div>
          <div style="z-index:0;position:absolute;top:-1px;left:-8px;width:100%;">
            <img src="@/assets/feature_03_img_bg.png" alt="" style="opacity: 0.4;" width="88%" />
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="5">
          <div
            :style="{
              height: '100%',
              width: '100%',
              display: 'flex',
              'align-items': 'center',
              'margin-top': $store.state.screenWidth < 960 ? '16px' : '0px'
            }"
          >
            <v-row no-gutters style="width:100%;">
              <v-col cols="12" id="RecordByPhotoTitle" :style="{ width: '100%', 'text-align': $store.state.screenWidth < 960 ? 'center' : 'left' }">
                <span
                  :style="{
                    'font-size': getTitleFontSize + 'px',
                    'font-weight': 'bold',
                    color: '#FFF'
                  }"
                  >拍照批貨</span
                >
              </v-col>
              <v-col cols="12" id="RecordByPhotoContent" :style="{ width: '100%', padding: $store.state.screenWidth < 960 ? '0 60px' : '0 0' }">
                <span
                  :style="{
                    color: '#FFF',
                    'font-size': getContentFontSize + 'px'
                  }"
                  >批貨採購新革命！用拍照的方式直接記錄商品，顏色、尺寸直接點選，價格也可依照您的設定自動生成建議價格，降低出錯率，批貨效率提升
                  500%</span
                >
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="0" sm="2" md="3"></v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    getTitleFontSize() {
      return this.$store.state.screenWidth / 30 < 30 ? 30 : this.$store.state.screenWidth / 30;
    },
    getContentFontSize() {
      return this.$store.state.screenWidth / 80 < 14 ? 14 : this.$store.state.screenWidth / 80;
    },
    getMarginTopAndBottom() {
      let ans;
      if (this.$store.state.screenWidth < 600) {
        ans = this.$store.state.screenHeight * 0.1;
      } else if (this.$store.state.screenWidth >= 600 && this.$store.state.screenWidth < 960) {
        ans = this.$store.state.screenHeight * 0.11;
      } else if (this.$store.state.screenWidth >= 960 && this.$store.state.screenWidth < 1264) {
        ans = this.$store.state.screenHeight * 0.12;
      } else {
        ans = this.$store.state.screenHeight * 0.13;
      }
      return ans;
    }
  }
};
</script>

<style scoped>
.RecordByPhotoImg {
  animation-duration: 1s;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.RecordByPhotoTitle {
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.RecordByPhotoContent {
  animation-duration: 1s;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
</style>
