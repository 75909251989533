<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-icon style="position:absolute;top:10px;right:10px;" @click="closeWeChatDialog">mdi-close</v-icon>
      <v-card-title>
        <strong>WeChat</strong>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters style="width:100%" align="center" justify="center">
          <div style="width:260px;margin:0 auto;">
            <span>WeChatID: <strong style="color:#1976d2;">EppBuyer</strong></span>
          </div>
          <img src="@/assets/qrcode.png" alt="" width="300px" />
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="closeWeChatDialog">OK </v-btn>
      </v-card-actions>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, required: true }
  },
  methods: {
    closeWeChatDialog() {
      this.$emit("closeWeChatDialog");
    }
  }
};
</script>
