<template>
  <v-row no-gutters align="center" justify="center" style="margin:70px 0;width:100%;" id="priceMenuContainer">
    <v-col cols="1" sm="1" md="1" lg="2" xl="3"></v-col>
    <v-col cols="10" sm="10" md="10" lg="8" xl="6">
      <v-tabs grow v-model="currentTab" style="border-radius:10px;">
        <v-tab key="month" style="font-size:18px;">月繳方案</v-tab>
        <v-tab key="year" style="font-size:18px;">年繳方案</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab" style="padding:20px 0;">
        <v-tab-item key="month">
          <v-card color="basil" flat>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" v-for="mItem in monthItems" :key="mItem.price">
                <div style="padding:8px;">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 5 : 1"
                      outlined
                      :class="{ 'on-hover': hover }"
                      style="cursor:pointer;border-radius: 20px;max-width:320px;"
                      @click="showDialog(mItem)"
                    >
                      <v-card-text>
                        <CardItem :itemObj="mItem"></CardItem>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>

        <v-tab-item key="year">
          <v-card color="basil" flat>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" v-for="yItem in yearItems" :key="yItem.price">
                <div style="padding:8px;">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 5 : 1"
                      outlined
                      :class="{ 'on-hover': hover }"
                      style="cursor:pointer;border-radius: 20px;max-width:320px;"
                      @click="showDialog(yItem)"
                    >
                      <v-card-text>
                        <CardItem :itemObj="yItem"></CardItem>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    <v-col cols="1" sm="1" md="1" lg="2" xl="3"></v-col>
  </v-row>
</template>

<script>
import CardItem from "@/components/sub_components/PriceMenu_CardItem.vue";
import { monthItems, yearItems } from "@/public_data/member_level.js";

export default {
  data() {
    return {
      currentTab: null,
      monthItems: monthItems,
      yearItems: yearItems
    };
  },
  components: {
    CardItem
  },
  methods: {
    showDialog(item) {
      this.leaveFocus();
      this.$emit("showBuyDialog", item);
    },
    leaveFocus() {
      if (document.activeElement != document.body) {
        document.activeElement.blur();
      }
    }
  }
};
</script>

<style scoped></style>
