<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-icon style="position:absolute;top:10px;right:10px;" @click="closeDialog">mdi-close</v-icon>
      <v-card-title>
        <strong>購買方案</strong>
      </v-card-title>

      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text style="padding:14px 25px;">
            <span> 請選擇您要購買的方案及輸入您的 EppBuyer 登入帳號 </span>
            <v-select
              label="購買方案"
              :items="selectorItems"
              :item-text="selectorItems.text"
              v-model="columnValues.currentCase"
              @change="changeHandler($event)"
              style="margin-top:16px;"
            ></v-select>
            <v-text-field
              label="EppBuyer 帳號"
              placeholder="請輸入您的 EppBuyer 帳號"
              v-model.trim="columnValues.userAccount"
              :error-messages="errorMsg.userAccount"
              @input="errorMsg.userAccount = ''"
              @blur="columnValues.userAccount = columnValues.userAccount.trim()"
              maxlength="60"
              spellcheck="false"
            ></v-text-field>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text style="padding:14px 25px;">
            <div style="margin-bottom:16px;">
              <span>我們目前僅提供匯款/轉帳的方式付款，請您匯款/轉帳到下面銀行帳號</span>
            </div>
            <v-row no-gutters align="center" justify="center" style="margin-bottom:20px;">
              <div style="width:auto;display:inline-block !important;display:inline;">
                <div style="font-weight:bold;font-size:16px;">
                  <span>銀行：渣打銀行</span>
                </div>
                <div style="font-weight:bold;font-size:16px;">
                  <span>銀行代碼：052</span>
                </div>
                <div style="font-weight:bold;font-size:16px;">
                  <span>帳號：36210053480</span>
                </div>
                <!-- <div style="font-weight:bold;font-size:16px;">
                  <span>戶名：XXX</span>
                </div> -->
              </div>
            </v-row>

            <div style="margin-bottom:6px;margin-top:10px;">
              <span>※ 請確認您選擇的方案、金額及 EppBuyer 帳號</span>
            </div>

            <v-row no-gutters align="center" justify="center" style="padding:10px 4px;border:1px solid #999;border-radius:5px;">
              <div style="width:auto;display:inline-block !important;display:inline;">
                <div>
                  <span
                    >方案：<strong>{{ columnValues.currentCase.monthOrYear }}繳 - {{ columnValues.currentCase.case }}</strong>
                  </span>
                </div>
                <div>
                  <span
                    >金額：NTD <strong>{{ columnValues.currentCase.money }}</strong> 元</span
                  >
                </div>
                <div>
                  <span
                    >EppBuyer 帳號：<strong style="font-size:13px;">{{ columnValues.userAccount }}</strong></span
                  >
                </div>
              </div>
            </v-row>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="3">
          <v-card-text style="padding:14px 25px;">
            <div style="margin-bottom:16px;">
              <span>請輸入您付款銀行帳號末五碼、付款金額及付款時間</span>
            </div>
            <v-row no-gutters style="width:100%">
              <v-col cols="6" style="padding-right:8px;">
                <v-text-field
                  label="* 銀行帳號末5碼"
                  placeholder="必填"
                  v-model="columnValues.lastFiveNumber"
                  :error-messages="errorMsg.lastFiveNumber"
                  @input="errorMsg.lastFiveNumber = ''"
                  oninput="javascript: if (this.value.length > 5) this.value = this.value.slice(0, 5);"
                  maxlength="5"
                  type="number"
                  class="inputPrice"
                ></v-text-field>
              </v-col>
              <v-col cols="6" style="padding-left:8px;">
                <v-text-field
                  label="* 付款金額"
                  placeholder="必填"
                  v-model="columnValues.paidMoney"
                  :error-messages="errorMsg.paidMoney"
                  @input="errorMsg.paidMoney = ''"
                  oninput="javascript: if (this.value.length > 5) this.value = this.value.slice(0, 5);"
                  maxlength="5"
                  class="inputPrice"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-menu v-model="showDateMenu" :close-on-content-click="false" :nudge-top="20" transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="columnValues.paidTime"
                  :error-messages="errorMsg.paidTime"
                  @click="errorMsg.paidTime = ''"
                  label="* 付款日期"
                  placeholder="必選"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="columnValues.paidTime" @input="showDateMenu = false" locale="cn" no-title></v-date-picker>
            </v-menu>

            <div style="margin-bottom:16px;margin-top:12px;">
              <span>以下為選填欄位，您可以留下您的聯絡資訊，如對帳有誤時我們才可快速的與您取得聯繫</span>
            </div>
            <v-row no-gutters style="width:100%">
              <v-col cols="6" style="padding-right:8px;">
                <v-text-field
                  label="付款人姓名"
                  placeholder="選填"
                  v-model.trim="columnValues.payerName"
                  oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                  maxlength="30"
                  class="inputPrice"
                ></v-text-field>
              </v-col>
              <v-col cols="6" style="padding-left:8px;">
                <v-text-field
                  label="手機號碼"
                  placeholder="選填"
                  v-model.trim="columnValues.payerPhone"
                  oninput="javascript: if (this.value.length > 15) this.value = this.value.slice(0, 15);"
                  maxlength="15"
                  class="inputPrice"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  label="其他備註"
                  placeholder="選填"
                  v-model="columnValues.payerRemarks"
                  oninput="javascript: if (this.value.length > 50) this.value = this.value.slice(0, 50);"
                  maxlength="50"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="4">
          <v-card-text style="padding:14px 25px;">
            <div>
              <span>※ 請再次仔細檢查您提供的各項資訊，確認無誤後請點擊「送出」按鈕</span>
            </div>
            <v-row no-gutters style="width:100%;margin-top:30px;margin-bottom:16px;" align="center" justify="center">
              <div style="width:auto;display:inline-block !important;display:inline;">
                <div>
                  <span
                    >EppBuyer 帳號：<strong style="color:#1565C0;">{{ columnValues.userAccount }}</strong></span
                  >
                </div>
                <div>
                  <span
                    >方案：<strong style="color:#1565C0;">{{ columnValues.currentCase.monthOrYear }}繳 - {{ columnValues.currentCase.case }}</strong>
                  </span>
                </div>
                <div>
                  <span
                    >方案價格：NTD <strong style="color:#1565C0;">{{ columnValues.currentCase.money }}</strong> 元</span
                  >
                </div>
                <div>
                  <span
                    >銀行帳號末5碼：<strong style="color:#1565C0;">{{ columnValues.lastFiveNumber }}</strong></span
                  >
                </div>
                <div>
                  <span
                    >實付金額：NTD <strong style="color:#1565C0;">{{ parseInt(columnValues.paidMoney) }}</strong> 元</span
                  >
                </div>
                <div>
                  <span
                    >付款日期：<strong style="color:#1565C0;">{{ columnValues.paidTime }}</strong></span
                  >
                </div>
                <div v-if="columnValues.payerName != ''">
                  <span
                    >付款人：<strong style="color:#1565C0;">{{ columnValues.payerName }}</strong></span
                  >
                </div>
                <div v-if="columnValues.payerName != ''">
                  <span
                    >手機號碼：<strong style="color:#1565C0;">{{ columnValues.payerPhone }}</strong></span
                  >
                </div>
                <div v-if="columnValues.payerRemarks != ''" style="max-width:340px;">
                  <span class="no_overflow"
                    >其他備註：<strong style="color:#1565C0;">{{ columnValues.payerRemarks }}</strong></span
                  >
                </div>
              </div>
            </v-row>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="5">
          <div class="pa-4 text-center">
            <v-img class="mb-4" contain height="128" src="@/assets/logo.png"></v-img>
            <h3 class="title font-weight-light mb-2">感謝您的購買</h3>
            <div style="width:100%;text-align:center;padding:0 70px;">
              <span class="caption">我們會盡快為您核對款項，確認無誤後將立即開通服務</span>
            </div>
          </div>
        </v-window-item>
      </v-window>

      <v-card-actions>
        <v-btn :loading="loading" :disabled="loading" v-if="step != 1 && step != 5" depressed color="primary" @click="step--">
          上一步
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" :disabled="loading" color="primary" depressed @click="NextBtnClickHandler">
          {{ step == 5 ? "完成" : step == 4 ? "送出" : "下一步" }}
        </v-btn>
      </v-card-actions>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import { monthItems, yearItems } from "@/public_data/member_level.js";

export default {
  props: {
    show: { type: Boolean, required: true },
    itemObj: { type: Object, required: true }
  },
  watch: {
    show: function() {
      if (this.show) {
        this.columnValues.currentCase = {
          text: this.itemObj.monthOrYear + "繳-" + this.itemObj.memberName + "-" + this.itemObj.price + "元",
          case: this.itemObj.memberName,
          level: this.itemObj.memberLevel,
          money: this.itemObj.price,
          monthOrYear: this.itemObj.monthOrYear,
          projectQty: this.itemObj.projectQty,
          productQty: this.itemObj.productQty
        };
        this.selectorItems = this.getSelectorItems();
      } else {
        this.step = 1;
        this.columnValues = this.getColumnValues();
        this.errorMsg = this.getErrorMsg();
      }
    }
  },
  data() {
    return {
      step: 1,
      loading: false,
      monthItems: monthItems,
      yearItems: yearItems,
      columnValues: {
        currentCase: {},
        userAccount: "",
        lastFiveNumber: "",
        paidMoney: "",
        paidTime: "",
        payerName: "",
        payerPhone: "",
        payerRemarks: ""
      },
      errorMsg: { userAccount: "", lastFiveNumber: "", paidMoney: "", paidTime: "" },
      showDateMenu: false,
      selectorItems: []
    };
  },
  methods: {
    getSelectorItems() {
      let arr = [];
      monthItems.forEach(item => {
        arr.push({
          text: "月繳-" + item.memberName + "-" + item.price + "元",
          case: item.memberName,
          level: item.memberLevel,
          money: item.price,
          monthOrYear: item.monthOrYear,
          projectQty: item.projectQty,
          productQty: item.productQty
        });
      });
      yearItems.forEach(item => {
        arr.push({
          text: "年繳-" + item.memberName + "-" + item.price + "元",
          case: item.memberName,
          level: item.memberLevel,
          money: item.price,
          monthOrYear: item.monthOrYear,
          projectQty: item.projectQty,
          productQty: item.productQty
        });
      });
      return arr;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    NextBtnClickHandler() {
      if (this.step != 5) {
        if (this.step == 1) {
          if (this.columnValues.currentCase != "") {
            if (this.accountValidation()) {
              this.step += 1;
            }
          }
        } else if (this.step == 2) {
          this.step += 1;
        } else if (this.step == 3) {
          if (this.paidValidation()) {
            this.step += 1;
          }
        } else if (this.step == 4) {
          this.updateToServer();
        }
      } else {
        this.closeDialog();
      }
    },
    accountValidation() {
      const regexEmail = /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(]?)$/;
      if (this.columnValues.userAccount.length === 0) {
        this.errorMsg.userAccount = "請輸入帳號";
      }
      if (this.errorMsg.userAccount == "") {
        if (!regexEmail.test(this.columnValues.userAccount)) {
          this.errorMsg.userAccount = "帳號格式錯誤 (例：abc@xyz.com)";
        }
      }
      if (this.errorMsg.userAccount != "") {
        return false;
      } else {
        return true;
      }
    },
    paidValidation() {
      if (this.columnValues.lastFiveNumber.length === 0) {
        this.errorMsg.lastFiveNumber = "請輸入付款帳號末5碼";
      }
      if (this.columnValues.lastFiveNumber.length != 5) {
        this.errorMsg.lastFiveNumber = "請輸入付款帳號末5碼";
      }
      if (this.errorMsg.lastFiveNumber == "") {
        if (this.columnValues.paidMoney == "") {
          this.errorMsg.paidMoney = "請輸入付款金額";
        }
      }
      if (this.errorMsg.lastFiveNumber == "" && this.errorMsg.paidMoney == "") {
        if (this.columnValues.paidTime == "") {
          this.errorMsg.paidTime = "請選擇付款日期";
        }
      }
      if (this.errorMsg.lastFiveNumber == "" && this.errorMsg.paidMoney == "" && this.errorMsg.paidTime == "") {
        return true;
      } else {
        return false;
      }
    },
    getColumnValues() {
      return {
        currentCase: {},
        userAccount: "",
        lastFiveNumber: "",
        paidMoney: "",
        paidTime: "",
        payerName: "",
        payerPhone: "",
        payerRemarks: ""
      };
    },
    getErrorMsg() {
      return { userAccount: "", lastFiveNumber: "", paidMoney: "", paidTime: "" };
    },
    async updateToServer() {
      this.loading = true;
      await this.$axios
        .post("/api/apis/z_noticepaid.php", JSON.stringify(this.columnValues))
        .then(
          function(res) {
            if (res.data == "success") {
              this.$store.commit("setMsg", {
                show: true,
                text: "送出購買方案通知成功",
                color: "#66BB6A",
                icon: "mdi-check-circle-outline",
                timeout: 3000
              });
              this.step += 1;
              this.loading = false;
            } else {
              if (res.data == "error#1") {
                this.$store.commit("setMsg", { show: true, text: "錯誤" });
              } else if (res.data == "error#2") {
                this.$store.commit("setMsg", {
                  show: true,
                  text: "已註冊帳號中沒有找到 " + this.columnValues.userAccount + " 這個帳號，請重新確認您的 EppBuyer 帳號"
                });
                this.step = 1;
              }
              this.loading = false;
            }
          }.bind(this)
        )
        .catch(
          function(err) {
            this.$store.commit("setMsg", { show: true, text: err.getErrorMsg });
            this.loading = false;
          }.bind(this)
        );
    },
    changeHandler(itemtext) {
      console.log(itemtext);
      this.selectorItems.forEach(item => {
        if (item.text == itemtext) {
          this.columnValues.currentCase = item;
        }
      });
      console.log(this.columnValues.currentCase);
    }
  }
};
</script>

<style scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.no_overflow {
  display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
  -webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
  overflow: hidden; /*超出的文本隐藏*/
  text-overflow: ellipsis; /* 溢出用省略号*/
  -webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
}
</style>
