<template>
  <v-row no-gutters align="center" justify="center" style="margin-top:70px;width:100%;" id="priceMenuContainer">
    <v-col cols="0" sm="2" md="2"></v-col>
    <v-col cols="12" sm="8" md="8">
      <v-tabs grow v-model="currentTab" style="border-radius:10px;">
        <v-tab key="month" style="font-size:18px;">月繳方案</v-tab>
        <v-tab key="year" style="font-size:18px;">年繳方案</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab" style="padding:20px 0;">
        <v-tab-item key="month">
          <v-carousel hide-delimiter-background show-arrows interval="360000" :hide-delimiters="true" :continuous="false" height="100%">
            <v-carousel-item v-for="mItem in monthItems" :key="mItem.price">
              <v-hover v-slot:default="{ hover }">
                <NewCardItem :itemObj="mItem" :hover="hover" @showDialog="showDiaog"></NewCardItem>
              </v-hover>
            </v-carousel-item>
          </v-carousel>
        </v-tab-item>
        <v-tab-item key="year">
          <v-carousel hide-delimiter-background show-arrows interval="360000" :hide-delimiters="true" :continuous="false" height="100%">
            <v-carousel-item v-for="yItem in yearItems" :key="yItem.price">
              <v-hover v-slot:default="{ hover }">
                <NewCardItem :itemObj="yItem" :hover="hover" @showDialog="showDiaog"></NewCardItem>
              </v-hover>
            </v-carousel-item>
          </v-carousel>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    <v-col cols="0" sm="2" md="2"></v-col>
  </v-row>
</template>

<script>
import NewCardItem from "@/components/sub_components/PriceMenu_NewCardItem.vue";
import { monthItems, yearItems } from "@/public_data/member_level.js";

export default {
  data() {
    return {
      currentTab: null,
      currentCard: null,
      monthItems: monthItems,
      yearItems: yearItems
    };
  },
  components: {
    NewCardItem
  },
  methods: {
    showDiaog(item) {
      this.leaveFocus();
      this.$emit("showBuyDialog", item);
    },
    leaveFocus() {
      if (document.activeElement != document.body) {
        document.activeElement.blur();
      }
    }
  }
};
</script>

<style scoped></style>
