<template>
  <v-row no-gutters align="center" justify="center" :style="{ width: '100%', margin: getMarginTopAndBottom + 'px 0' }">
    <v-col cols="12" style="text-align:center;">
      <div>
        <span :style="{ 'font-size': getTitleFontSize + 'px', 'font-weight': 'bold' }">還在手寫批貨嗎?</span>
      </div>
    </v-col>
    <v-col cols="12">
      <div style="max-width:600px;margin:0 auto;padding:0 45px;">
        <span :style="{ 'font-size': getContentFontSize + 'px' }"
          >每次批貨採購，都要一筆一筆手寫記錄，雜亂又麻煩，常常記錄跟採購的商品對不上！最後還要一筆一筆輸入電腦，上架時再重複一遍相同的動作，沒效率又容易出錯！
          有更好的解決辦法嗎？</span
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    getTitleFontSize() {
      return parseInt(this.$store.state.screenWidth / 14) > 38
        ? 38
        : parseInt(this.$store.state.screenWidth / 14) < 18
        ? 18
        : parseInt(this.$store.state.screenWidth / 14);
    },
    getContentFontSize() {
      return this.$store.state.screenWidth / 80 < 14 ? 14 : this.$store.state.screenWidth / 80 > 18 ? 18 : this.$store.state.screenWidth / 80;
    },
    getMarginTopAndBottom() {
      let ans;
      if (this.$store.state.screenWidth < 600) {
        ans = this.$store.state.screenHeight * 0.1;
      } else if (this.$store.state.screenWidth >= 600 && this.$store.state.screenWidth < 960) {
        ans = this.$store.state.screenHeight * 0.11;
      } else if (this.$store.state.screenWidth >= 960 && this.$store.state.screenWidth < 1264) {
        ans = this.$store.state.screenHeight * 0.12;
      } else {
        ans = this.$store.state.screenHeight * 0.13;
      }
      return ans;
    }
  }
};
</script>
