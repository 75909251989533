<template>
  <v-row no-gutters align="center" justify="center" style="margin:100px 0;">
    <v-col cols="12" style="text-align:center;">
      <span style="font-size:36px;font-weight:bold;">Time is money!</span>
    </v-col>
    <v-col cols="12" style="text-align:center;padding:0 30px;">
      <span style="color:#AAA;font-size:12px;">You have no idea how much time you can save!</span>
    </v-col>
    <v-col cols="12" style="text-align:center;padding:0 42px;">
      <span>時間就是金錢！ EppBuyer 幫您解決大部份批貨的麻煩，節省人力時間，讓您專注於行銷！</span>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>
