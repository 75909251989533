<template>
  <div style="width:100%;">
    <v-row no-gutters align="center" justify="center" style="background-color:#616161;height:60px;line-height:60px;">
      <div style="text-align:center;height:60px;">
        <span style="color:white;font-size:13px;">Copyright © 2020 EPPBUYER. All Rights Reserved.</span>
      </div>
    </v-row>
    <v-icon @click="toTop" dark style="position:absolute;bottom:18px;right:20px;">mdi-arrow-up-drop-circle-outline</v-icon>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toTop() {
      this.$store.commit("setShowDrawer", false);
      let scrollToTop = window.setInterval(() => {
        let Top = document.documentElement.scrollTop || document.body.scrollTop;
        if (Top > 0) {
          window.scrollTo(0, Top - 64);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 5);
      this.leaveFocus();
    },
    toFacebook() {
      setTimeout(() => {
        window.open("https://www.facebook.com/eppbuyer", "_blank");
      }, 100);
      this.leaveFocus();
    },
    toWechat() {
      this.leaveFocus();
    },
    leaveFocus() {
      if (document.activeElement != document.body) {
        document.activeElement.blur();
      }
    }
  }
};
</script>
